<template>
  <div class="text-center">
    <v-bottom-sheet persistent :value="showPlayer">
      <audio
        id="player"
        ref="player"
        v-on:ended="ended"
        v-on:canplay="canPlay"
        :src="currentChapter.mp3"
      ></audio>
      <div class="text-right">
        <v-btn icon small fab color="white" @click="closePlayer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card tile>
        <v-progress-linear
          v-model="percentage"
          @click.native="setPosition()"
          :disabled="!loaded"
          class="my-0"
          height="3"
          color="cyan darken-2"
        ></v-progress-linear>
        <v-card-actions class="text-caption px-4">
          <span>{{currentTime}}</span>
          <v-spacer></v-spacer>
          <span>{{timeLeft}}</span>
        </v-card-actions>
        <v-list class="pt-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold cyan--text text--darken-2">{{ currentChapter.play }}</v-list-item-title>
              <v-list-item-subtitle><span class="font-weight-bold">Capítulo {{ currentChapter.chapter }}:</span> {{ currentChapter.title }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-spacer></v-spacer>

            <v-list-item-icon>
              <v-btn icon @click="rewind">
                <v-icon>mdi-rewind</v-icon>
              </v-btn>
            </v-list-item-icon>

            <v-list-item-icon :class="{ 'mx-5': $vuetify.breakpoint.mdAndUp }">
              <v-btn icon @click.native="playing ? pause() : play()">
                <v-icon v-if="!playing || paused">mdi-play</v-icon>
                <v-icon v-else>mdi-pause</v-icon>
              </v-btn>
            </v-list-item-icon>

            <v-list-item-icon
              class="ml-0"
              :class="{ 'mr-3': $vuetify.breakpoint.mdAndUp }"
            >
              <v-btn icon @click="fastForward">
                <v-icon>mdi-fast-forward</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
const formatTime = second =>
  new Date(second * 1000).toISOString().substr(11, 8);
export default {
  props: {
    showPlayer: Boolean,
    currentChapter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00:00:00",
      timeLeft: "00:00:00",
      audio: undefined,
      totalDuration: 0,
    };
  },
  methods: {
    setPosition() {
      this.audio.currentTime = parseInt(
        (this.audio.duration / 100) * this.percentage
      );
    },
    closePlayer() {
      this.$emit("close-player");
    },
    play() {
      if (this.playing) return;
      this.audio.play().then(audio => {
        this.playing = true;
        console.log(audio);
      });
      this.paused = false;
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    rewind(){
      this.audio.currentTime = parseInt(this.audio.currentTime - 10) 
    },
    fastForward(){
      this.audio.currentTime = parseInt(this.audio.currentTime + 10) 
    },
    ended() {},
    canPlay() {},
    _handleLoaded: function() {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }
        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    _handlePlayingUI: function() {
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
      this.timeLeft = formatTime(this.audio.duration - this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function(e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function() {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
      this.play()
    }
  },
  mounted() {
    this.audio = this.$refs.player
    this.init();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  }
};
</script>